import React, { useCallback, useEffect, useMemo, useState } from 'react';
import style from './index.module.scss';
import { withRouter } from 'react-router';
import { Table, DatePicker, Button, Space } from "antd";
import { get, post } from 'common/utils.js';
import moment from 'moment';
import * as echarts from 'echarts~5/lib/echarts';
import DomResize from 'components/DomResize';
// // 引入柱状图
// import 'echarts/lib/chart/bar';
// // 引入提示框和标题组件
// import 'echarts/lib/component/tooltip';
// import 'echarts/lib/component/title';

// import echarts from 'echarts';
import { getCachedUserInfo } from 'common/utils.js';
import { chartObj, renderMonthlySummaryCell, menuList, getColumns, renderMonthlySummaryCellPercent, formatTimex, getDayColumns, DailyPredictionColumns, DailyChartDefaultColumns, defaultSuggestionColumns } from './utils'
import MonthlyChart from './MonthlyChart'
import ExcellentExport from 'excellentexport';
import { LeftOutlined, RightOutlined, DownloadOutlined } from '@ant-design/icons';
let timeRange = []
for (let index = 0; index < 24; index++) {
  timeRange.push(`${index + 1}:00`)
}


// const get_number_value_by_time = function(val, datetime) {
//   let now = new Date();
//   if (now < datetime) {
//       if (!val) {
//         return ""
//       }
//   } else {
//     if (val === 0 || !val) {
//       return 0
//     }
//   }
//   return val.toFixed(digit);
// }

const Page = (props) => {
  useEffect(() => {
    get(process.env.REACT_APP_SERVER_PATH + (props.match.url.indexOf('elestat') > -1 ? 'api/auth/checkpoint/estat' : 'api/auth/checkpoint/eplan'))
  }, [])

  // 用电监测-用电计划
  let defaultCurrentMenu = 2

  if (props.match.url.indexOf('elestat') > -1) {
    defaultCurrentMenu = 1
  }
  let { factoryId } = props.match.params

  const [hasAccess, setHasAccess] = useState(false)
  const [currentMenu, setCurrentMenu] = useState(defaultCurrentMenu)
  const [currentDate, setCurrentDate] = useState(moment(new Date((new Date().getFullYear()),
  (new Date().getMonth()),
  (new Date().getDate())) ))
  const [hasBack, setHasBack] = useState(defaultCurrentMenu == 1)

  const [monthColumns, setMonthColumns] = useState(() => getColumns())
  const [monthDataSource, setMonthDataSource] = useState([])

  const [dayDataSource, setDayDataSource] = useState([])
  const [DailyChartDataSource, setDailyChartDataSource] = useState([])
  const [DailyChartColumns, setDailyChartColumns] = useState([...DailyPredictionColumns])
  const [ProductionPlansDataSource, setProductionPlansDataSource] = useState([])
  const [SuggestionColumns, setSuggestionColumns] = useState([...defaultSuggestionColumns])
  const [dataByDay, setDataByDay ] = useState()
  const [ dailyPrediction, setDailyPrediction ] = useState()
  const [ factory, setFactory ] = useState("")
  const [ refNoPrice, setRefNoPrice ] = useState([])
  const initData = (date) => {
    if (currentMenu === 1) {
      GetMonthlySummary(date)
      GetDailySummary(date)
    } else {
      getChartData(date)
    }
  }

  const backToCurrentMenu = (CurrentMenu, time) => {
    setCurrentDate(time)
    setCurrentMenu(CurrentMenu)


    GetMonthlySummary(time)

    GetDailySummary(time)

    GetDailyDataByDay(time)
  }

  function GetMonthlySummary(date) {
    const year = moment(date).year()
    const month = moment(date).month() + 1
    get('https://hq01.quantile.tech:5000/api/Trader/GetMonthlySummary', {
      factoryId: factoryId,
      year,
      month
    }).then((res) => {
      let data = res.data
      const columns = getColumns()

      columns.forEach(header => {
        header.children.forEach(child => {
          if (child.children.length === 1) {

            if (child.key !== 'nokey') {
              if (child.isPercent) {
                child.children[0].title = renderMonthlySummaryCellPercent(data[child.key])
              } else {
                child.children[0].title = renderMonthlySummaryCell(data[child.key])
              }

            }
          } else {

          }

        })
      })

      setMonthColumns(columns)
      setMonthDataSource([{ ...data, age: 1, key: 1 }])


    }).catch(() => {
      const columns = getColumns()
      columns.forEach(header => {
        header.children.forEach(child => {
          if (child.children.length === 1) {

            if (child.key !== 'nokey') {
              if (child.isPercent) {
                child.children[0].title = null
              } else {
                child.children[0].title = null
              }

            }
          } else {

          }

        })
      })

      setMonthColumns(columns)
      setMonthDataSource([])
    })
  }

  function GetDailySummary(date) {
  }

  function GetDailyDataByDay(date) {
    const year = moment(date).year()
    const month = moment(date).month() + 1
    const day = moment(date).day()

    get('https://hq01.quantile.tech:5000/api/Trader/GetDailyDataByDay', {
      factoryId: factoryId,
      year,
      month,
      day

    }).then((res) => {
      let data = res.data
      data.forEach(item => {
        item.key = item.em_xday
      })
      setDayDataSource(data)
    }).catch(() => {
      setDayDataSource([])
    })
  }

  const [refreshInfo, setRefreshInfo] = useState(null)
  useEffect(() => {
    if (!!refreshInfo) {
      let delay = true
      const myInterval = setInterval(() => {
        if (delay) {
          refreshInfo?.func?.()
        }
      }, 60 * 1000 * 5)
      return () => {
        clearInterval(myInterval)
        delay = false
      }
    }
  }, [refreshInfo])

  // GetMonthlySummary()
  const getChartData = (date) => {
    const func = () => {
      GetDailyChart(date)
      GetDailyTrendForecast(date)
      GetSuggestion(date)
      GetProductionPlansByFactoryIdAndDate(date)
    }
    setRefreshInfo({ func })
    func()
  }

  const onChange = (data) => {
    initData(data)
  }

  const getNotNullData = (list, key, dataDate) => {

    let newList = list.map(data => data[key])

    if (key == 'hourly_actual_mwh') {
      let currentDate = moment().format('YYYYMMDD');
      dataDate = moment(dataDate).format('YYYYMMDD');
      // console.log('currentDate', currentDate, dataDate);
      if (currentDate == dataDate) {
        // console.log('newList', newList);
        let currentHour = moment().hour() + 1
        newList = list.filter(item => {
          return item.hour < currentHour || item[key]
        })
        newList = newList.map(data => data[key])

      } else if (currentDate < dataDate) {
        newList = []
      }
    }

    if (key == 'hourly_actual_price') {
      let currentDate = moment().format('YYYYMMDD');
      dataDate = moment(dataDate).format('YYYYMMDD');

      if (currentDate == dataDate) {

        newList = []
      } else if (currentDate < dataDate) {
        newList = []
      }
    }
    return newList
  }
  const setStackedLine = (list, currentDate, bckList, ref_nprice) => {
    list = [...list];
    if (list.length === ref_nprice.length)
    var i = 0;
    list = list.map(item => {
      let tmp = {};
      Object.keys(item).forEach(key => {
        if (key.startsWith("hourly") && item[key] > 0 ) {
          let t = item[key].toFixed(2);
          tmp[key] = t;
        } else {
          tmp[key] = item[key]
        }
      })
      tmp["ref_noprice"] = ref_nprice[i].REF_NODE_SPOT;
      i++;
      return tmp;
    })
    var chartDom = document.getElementById('StackedLine');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      title: {
        text: ``,
        left: 'center',
        top: 0,
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        left: 'center',
        top: 'top',
        orient: 'horizontal',
        itemHeight: 10,
        itemGap: 50,
        textStyle: {
          fontSize: 16
        },
        data: [
          "合约电量",
          "计划电量",
          "实际电量",
          "合约电价",
          "现货电价",
          "预测电价",
          "临界电价",
          "全网实时电能电价",
        ],
      },
      grid: {
        left: 20,
        right: 10,
        bottom: '10%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        nameLocation: 'center',
        boundaryGap: false,
        splitLine: {
          show: false
        },
        axisLabel : {
          fontSize: 16
        },
        nameTextStyle: {
          fontSize: 16
        },
        show: true,
        data: list.map(item => `${parseInt(item.timex.split(":")[0]-1)} - ${parseInt(item.timex.split(":")[0]) }`)
      },
      yAxis: [
        {
          type: 'value',
          name: '电量,MWH',
          splitLine: {
            show: false
          },
          axisLabel : {
            fontSize: 16
          },
          nameTextStyle: {
            fontSize: 16
          }
        },
        {
          type: 'value',
          name: '电价,元/MWH',
          splitLine: {
            show: false
          },
          axisLabel : {
            fontSize: 16
          },
          nameTextStyle: {
            fontSize: 16
          }
        }
      ],
      series: [
        chartObj.hourly_contract_mwh,
        chartObj.hourly_sys_plan_mwh,
        chartObj.hourly_actual_mwh,
        chartObj.hourly_contract_price,
        chartObj.hourly_spot_price,
        chartObj.hourly_predict_spot_price,
        chartObj.ref_noprice
      ]
        .map(item => {
          return {
            name: item.name,
            type: 'line',
            yAxisIndex: item.yAxisIndex,
            data: getNotNullData(list, item.key, currentDate),
            lineStyle: item.lineStyle,
            symbol: item.symbol,
            symbolSize: item.symbolSize || 6,
            itemStyle: item.itemStyle

          }
        })
        .concat((bckList ?? []).length > 0 ? [{
          name: '临界电价',
          type: 'line',
          yAxisIndex: 1,
          data: bckList,
          lineStyle: {
          },
          symbol: 'none',
          itemStyle: {
            color: 'rgba(252,221,222,0.5)'
          },
          emphasis: {
            disabled: true
          },
          areaStyle: {
            normal: {
              origin: 'end',
              color: "rgba(252,221,222,0.5)"
            }
          }
        }] : [])
    };

    option && myChart.setOption(option);
  }

  const setPercentBar = (list) => {
    var chartDom = document.getElementById('percent');
    var myChart = echarts.init(chartDom);
    console.log("list", list)
    let option = {
      tooltip: {},
      legend: {
        show: true,
        left: 'left',
        top: 'center',
        orient: 'vertical',
        // top: 11,
        textStyle: {
          fontSize: '20'
        },
        zlevel: 1,
        data: ['高于合约电价概率', '低于合约电价概率']
      },
      xAxis: [
        {
          gridIndex: 0,
          show: false,

          splitLine: {
            show: false
          },
          axisLabel: { interval: 0, fontSize: 16 },
          data: list.map(item => item.timex),
          nameTextStyle: {
            fontSize: 16
          },  
        },
        {
          // show:false,
          gridIndex: 1,
          // show:false,
          // inverse: true,

          data: list.map(item => item.timex),
          position: "top",

          axisLine: {
            onZero: true,
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            fontSize: 14 
          },
          zlevel: 1,
          nameTextStyle: {
            fontSize: 16
          },
        }
      ],
      yAxis: [
        {
          axisLine: {
            show: false
          },
          axisTick: {
            show: true
          },
          position: "left",
          splitArea: {
            show: false
          },
          show: false,
          interval: 0.3,
          max: 100,
          min: 0,
          nameTextStyle: {
            fontSize: 16
          },
        },
        {
          gridIndex: 1,
          position: "left",
          splitArea: {
            show: false
          },
          show: false,
          interval: 0.3,
          max: 0,
          min: -100,
          nameTextStyle: {
            fontSize: 16
          },
        }
      ],
      grid: [{
        top: "20%",
        bottom: "50.5%",
        left: 170,
        right: 0,
        containLabel: true
      }, {
        top: "50%",
        bottom: "20%",
        left: 170,
        right: 0,
        containLabel: true
      }],
      color: ['#0070c0', '#ffc000'],
      series: [
        {
          xAxisIndex: 0,
          yAxisIndex: 0,
          barWidth: '30%',
          name: '高于合约电价概率',
          type: 'bar',
          emphasis: {
            itemStyle: {
              barBorderWidth: 1,
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowColor: 'rgba(0,0,0,0.5)'
            }
          },

          itemStyle: {
            normal: {
              label: {
                show: true,

                position: 'top',
                formatter: (item) => {
                  return `${item.value}%`
                }
              }
            }
          },
          tooltip: {
            valueFormatter: (value) => {
              return `${value}%`
            }
          },
          data: list.map(item => item.ht_contract_price_prob),
        },
        {
          xAxisIndex: 1,
          yAxisIndex: 1,
          name: '低于合约电价概率',
          type: 'bar',
          barWidth: '30%',
          
          itemStyle: {
            normal: {
              label: {
                show: true,

                position: 'bottom',
                formatter: (item) => {

                  return `${item.value * -1}%`
                }
              }
            }
          },
          tooltip: {
            valueFormatter: (value) => {
              return `${value * -1}%`
            }
          },
          data: [...list.map(item => -item.lt_contract_price_prob)],
        },
      ]
    }

    option && myChart.setOption(option);
  }

  const setPercentTwoBar = (list) => {
    var chartDom = document.getElementById('percent1');
    var myChart = echarts.init(chartDom);


    let option = {
      tooltip: {},
      legend: {
        show: true,
        left: 'left',
        top: 'center',
        orient: 'vertical',
        // top: 11,
        zlevel: 1,
        textStyle: {
          fontSize: '20'
        },
        data: ['高于日均电价概率', '低于日均电价概率']
      },
      xAxis: [
        {
          gridIndex: 0,
          show: false,

          splitLine: {
            show: false
          },
          axisLabel: { interval: 0 },
          data: list.map(item => item.timex),

        },
        {
          // show:false,
          gridIndex: 1,

          data: list.map(item => item.timex),
          position: "top",

          axisLine: {
            onZero: true,
          },

          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          zlevel: 1
        }
      ],
      yAxis: [
        {
          axisLine: {
            show: false
          },
          axisTick: {
            show: true
          },
          nameTextStyle: {
            fontSize: 16
          },
          position: "left",
          splitArea: {
            show: false
          },
          show: false,
          interval: 0.3,
          max: 100,
          min: 0
        },
        {
          nameTextStyle: {
            fontSize: 16
          },
          gridIndex: 1,
          position: "left",
          splitArea: {
            show: false
          },
          show: false,
          interval: 0.3,
          max: 0,
          min: -100
        }
      ],
      grid: [{
        top: "20%",
        bottom: "50.5%",
        left: 70,
        right: 0,
        containLabel: true
      }, {
        top: "50%",
        bottom: "20%",
        left: 70,
        right: 0,
        containLabel: true
      }],
      color: ['#0070c0', '#ffc000'],
      series: [
        {
          xAxisIndex: 0,
          yAxisIndex: 0,
          name: '高于日均电价概率',
          type: 'bar',
          emphasis: {
            itemStyle: {
              barBorderWidth: 1,
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowColor: 'rgba(0,0,0,0.5)'
            }
          },
          barWidth: '30%',
          itemStyle: {
            normal: {
              label: {
                show: true,

                position: 'top',
                formatter: (item) => {
                  return `${item.value}%`
                }
              }
            }
          },
          tooltip: {
            valueFormatter: (value) => {
              return `${value}%`
            }
          },
          data: list.map(item => item.ht_target_price_prob),
        },
        {
          xAxisIndex: 1,
          yAxisIndex: 1,
          name: '低于日均电价概率',
          type: 'bar',
          barWidth: '30%',
          itemStyle: {
            normal: {
              label: {
                show: true,

                position: 'bottom',
                formatter: (item) => {
                  return `${item.value * -1}%`
                }
              }
            }
          },

          data: list.map(item => -item.lt_target_price_prob),
          tooltip: {
            valueFormatter: (value) => `${value}%`
          },
        },
      ]
    }

    option && myChart.setOption(option);
  }

  const GetDailyChart = (date) => {
    const dateTime = moment(date).format('YYYY-MM-DD')
    const year = moment(date).year()
    const month = moment(date).month() + 1
    const em_xday = moment(date).date()
    GetMonthlySummary(date)
    Promise.all([
      get('https://hq01.quantile.tech:5000/api/Trader/GetDailyChart', {
        factoryId: factoryId,
        dateTime,
      }),
      get('https://hq01.quantile.tech:5000/api/Trader/GetDailySummary', {
        factoryId: factoryId,
        year,
        month
      }),
      post("https://epublic.quantile.tech/ele_api/get_critical_eprice", {
        fid: parseInt(factoryId),
        date: dateTime
      }),
      get('https://hq01.quantile.tech:5000/api/Trader/GetDailyDataByDay', {
      factoryId: factoryId,
      year,
      month,
      day: em_xday
    }),
    get('https://hq01.quantile.tech:5000/api/Trader/GetHourlyDataWithPrediction', {
      factoryId: factoryId,
      year,
      month,
      day: em_xday
    }),
    post('https://epublic.quantile.tech/ele_api/get_ref_nprice', {
      fac_id: factoryId,
      date: dateTime
    })
    ]).then(([DailyChart, DailySummary, bckData, dataByDay, prediction, ref_nprice]) => {
      let bckList = null
      if (bckData?.data?.errcode === 0) {
        bckList = (bckData?.data?.results ?? []).map(({ hourly_critical_eprice_ulim }) => typeof hourly_critical_eprice_ulim === 'number' ? Math.round(hourly_critical_eprice_ulim * 100) / 100 : null)
      }
      let data = DailyChart.data;

      let DailyChartColumns = [...DailyChartDefaultColumns]

      let summary = DailySummary.data.find(item => item.em_xday == em_xday)
      if (prediction.data && ref_nprice && ref_nprice.data.results && prediction.data.length == ref_nprice.data.results.length ) {
        prediction.data.forEach((item, i) => {
          item["REF_NODE_SPOT"] = ref_nprice.data.results[i].REF_NODE_SPOT
        })
      }
      setCurrentDate(moment(date))
      setStackedLine(data, date, [], ref_nprice.data.results)
      setDailyChartDataSource((DailyChartDataSource ?? []).map((item, index) => ({ ...item, key: `${index}` })))
      setDataByDay(dataByDay.data)
      setDailyPrediction(prediction.data)
      setRefNoPrice(ref_nprice.data)
      setTimeout(() => {
        document.querySelector("table").id = "datatable";
      }, 500)
      
    }).catch(() => {
      setStackedLine([], date, [], [])                                                             
      setDailyChartDataSource([])
    })
  }

  const greatThanToday = () => {
    let s = new Date(currentDate);
    return new Date((new Date().getFullYear()),
                                      (new Date().getMonth()) + 1,
                                      (new Date().getDate())) <=

                                      new Date(s.getFullYear(),
                                         s.getMonth() + 1,
                                      s.getDate())
  }

  const GetDailyTrendForecast = (date) => {
    const dateTime = moment(date).format('YYYY-MM-DD')
    get('https://hq01.quantile.tech:5000/api/Trader/GetDailyTrendForecast', {
      factoryId: factoryId,
      dateTime,
    }).then((res) => {
      let data = res.data
      data.forEach(item => {
        item.ht_contract_price_prob = renderMonthlySummaryCell(item.ht_contract_price_prob * 100)
        item.lt_contract_price_prob = renderMonthlySummaryCell(item.lt_contract_price_prob * 100)
        item.ht_target_price_prob = renderMonthlySummaryCell(item.ht_target_price_prob * 100)
        item.lt_target_price_prob = renderMonthlySummaryCell(item.lt_target_price_prob * 100)
      })
    }).catch(() => {
      setPercentBar([])
      setPercentTwoBar([])
    })
  }

  const GetSuggestion = (date) => {
    const dateTime = moment(date).format('YYYY-MM-DD')
    get('https://hq01.quantile.tech:5000/api/Trader/GetSuggestion', {
      factoryId: factoryId,
      dateTime,
    }).then((res) => {
      let data = res && res.data;

      let SuggestionColumns = [...defaultSuggestionColumns]

      let suggestion = ''
      if (data && data.suggestion) {
        suggestion = data.suggestion
      }
      SuggestionColumns.push({
        title: suggestion,
        dataIndex: 'key',
        key: 'key',
        className: 'elec-tabel-cell red fz16',
        align: 'center'
      })


      setSuggestionColumns(data)
    }).catch(() => {
      setSuggestionColumns([])
    })
  }

  const GetProductionPlansByFactoryIdAndDate = (date) => {
    const dateTime = moment(date).format('YYYY-MM-DD')
    get('https://hq01.quantile.tech:5000/api/Trader/GetProductionPlansByFactoryIdAndDate', {
      factoryId: factoryId,
      dateTime,
    }).then((res) => {
      let data = res.data;


      let ProductionPlansDataObj = {}
      let ProductionPlansDataSource = []
      data.forEach((item, index) => {
        let hourly_sys_plan_mwh = renderMonthlySummaryCell(item.hourly_sys_plan_mwh)
        if (item.sys_id == 0) {
          hourly_sys_plan_mwh = renderMonthlySummaryCell(hourly_sys_plan_mwh * 1000)
        }
        if (ProductionPlansDataObj[item.sys_id]) {
          ProductionPlansDataObj[item.sys_id][item.timex] = hourly_sys_plan_mwh
          ProductionPlansDataObj[item.sys_id].count += hourly_sys_plan_mwh
        } else {
          ProductionPlansDataObj[item.sys_id] = {
            ...item,
            name: item.sys_name + 'kW',
            unit: 'kW',
            count: hourly_sys_plan_mwh,
            [item.timex]: hourly_sys_plan_mwh,
            align: 'center'
          }
          ProductionPlansDataSource.push(ProductionPlansDataObj[item.sys_id])
        }
      })

      ProductionPlansDataSource.forEach(item => {
        item.count = renderMonthlySummaryCell(item.count / 24)
      })

      setProductionPlansDataSource((ProductionPlansDataSource ?? []).map((item, index) => ({ ...item, key: `${index}` })))

    }).catch(() => {
      setProductionPlansDataSource([])
    })
  }

  const getAccess = async () => {
    let res = await post(process.env.REACT_APP_SERVER_PATH + 'api/auth/roles/user_factory', {
      user_id: getCachedUserInfo().user_id
    })
    .catch(() => {
      return {}
    })
    let hasAccess = true
    try {
      hasAccess = res.data.results.factory_ids.some(item => item == factoryId)
    } catch (error) {

    }
    setHasAccess(hasAccess)


    if (hasAccess) {
      initData(currentDate)
    }
    // console.log('hasAccess', hasAccess);
  }
  useEffect(() => {
    getAccess()

    get('https://hq01.quantile.tech:5000/api/Trader/GetCustomerById?factoryId=' + factoryId)
    .then(res => {
      setFactory(res.data.name)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dayColumns = useMemo(() => {
    return getDayColumns(setCurrentDate, setCurrentMenu, getChartData)
  }, [getChartData])

  const [info, setInfo] = useState(null)

  const onResize = useCallback(() => {
    ['StackedLine', 'percent', 'percent1'].some(id => {
      const chartDom = document.getElementById(id);
      if (chartDom) {
        const instance = echarts.getInstanceByDom(chartDom);
        instance?.resize?.()
      }
      return false
    })
  }, []);

  const importar = (e) => {
    return ExcellentExport.excel(e.target, document.querySelector("#datatable"), currentDate + "日出清结果.xlsx");
  };

  const goBack = () => {
    onChange(  moment(new Date((currentDate.format("X") - 86400) * 1000) ));
  }

  const goForward = () => {
    
    onChange(  moment(new Date((parseInt(currentDate.format("X")) + 86400) * 1000) ));
  }

  const dailyAcutal = () => {
    let s = dailyPrediction ? dailyPrediction.reduce((sum, r) => {
      sum += r["hourly_actual_mwh"];
      return sum;
    }, 0) : -1;
    if (s === 0) {
      s = -1;
    }
    return s;
  }

  const reduce = (key, array) => {
    return array.reduce((sum, r) => {
      if (r[key]) {
        sum += r[key];
      }
      return sum;
    }, 0);
  }


  const dailyPredictionAndSum = () => {
    if (dailyPrediction) {
      let hourly_actual_mwh;
      if (!reduce("hourly_actual_mwh", dailyPrediction) &&
         dailyPrediction.filter(r => r["hourly_actual_mwh"] > 0).length > 0) {
        hourly_actual_mwh = 0
      } else if (!reduce("hourly_actual_mwh", dailyPrediction) &&
         dailyPrediction.filter(r => r["hourly_actual_mwh"] > 0).length == 0) {
          hourly_actual_mwh = ""
      } else {
        hourly_actual_mwh = reduce("hourly_actual_mwh", dailyPrediction).toFixed(2)
      }

      let hourly_actual_price;
      if (!dataByDay.daily_mean_actual_price &&
          dailyPrediction.filter(r => r["hourly_actual_price"] > 0).length > 0) {
            hourly_actual_price = 0
      } else if (!dataByDay.daily_mean_actual_price &&
        dailyPrediction.filter(r => r["hourly_actual_price"] > 0).length == 0) {
          hourly_actual_price = ""
      } else {
        hourly_actual_price = dataByDay.daily_mean_actual_price.toFixed(2)
      }

      let hourly_spot_price;
      if (!reduce("hourly_spot_price", dailyPrediction)) {
        if (dailyPrediction.filter(r => r["hourly_spot_price"] > 0).length > 0) {
          hourly_spot_price = 0;
        } else {
          hourly_spot_price = "";
        }
      } else {
        hourly_spot_price = (reduce("hourly_spot_price", dailyPrediction) / dailyPrediction.filter(r => r["hourly_spot_price"] > 0).length).toFixed(2);
      }
      
      let REF_NODE_SPOT = !dailyPrediction.filter(r => r["REF_NODE_SPOT"] > 0).length ? "" :
      reduce("REF_NODE_SPOT", dailyPrediction) / dailyPrediction.filter(r => r["REF_NODE_SPOT"] > 0).length
      return [...dailyPrediction,
      {
        hourly_contract_mwh: reduce("hourly_contract_mwh", dailyPrediction),
        hourly_contract_price: dataByDay.daily_mean_contract_price,
        hourly_spot_price: hourly_spot_price, 
        hourly_actual_mwh: hourly_actual_mwh,
        hourly_actual_price: hourly_actual_price,
        hourly_predict_spot_price: reduce("hourly_predict_spot_price", dailyPrediction) / dailyPrediction.filter(r => r["hourly_predict_spot_price"] > 0).length, 
        hourly_sys_plan_mwh: reduce("hourly_sys_plan_mwh", dailyPrediction),
        REF_NODE_SPOT: REF_NODE_SPOT, 
      }]
    } else {
      return []
    }
  }
 
  return (
    
    <div className={style['wrapper']}>
      <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
     
        <DomResize mountedCall={true} onResize={onResize} /> 
      </div>
      {
        !hasAccess ? (<div></div>) :
          currentMenu === 1 ? (
            <div className='body-right' style={info ? { display: 'flex', flexDirection: 'column' } : { }}>
              <div className='body-title'>
                <div>
                  <span className='parent-menu'>
                    {factory}
                  </span>
                
                  {
                    currentMenu == 1 ? (<span className='unit'>
                      单位（电量,MWH 电价,元/MWH）
                    </span>) : null
                  }
                </div>


                <Space>
                  {
                    info
                      ? <Button type='primary' onClick={() => setInfo(null)}>月度统计表</Button>
                      : <Button type='primary' onClick={() => setInfo({})}>月度趋势图</Button>
                  }
                  <DatePicker
                    value={currentDate}
                    onChange={onChange}
                    allowClear={false}
                    picker='month' />
                </Space>
              </div>
              
              <div style={info ? { display: 'none' } : {}}>
                <div>
                  <Table
                    size='small'
                    pagination={false}
                    bordered
                    dataSource={monthDataSource}
                    columns={monthColumns}
                  />

                </div>
                <div className='day-table'>
                  <Table
                    size='small'
                    pagination={false}
                    bordered
                    dataSource={dayDataSource}
                    columns={dayColumns}
                  />

                </div>
              </div>
              {
                info && (
                  <div style={{ height: 0, flexGrow: 1 }}>
                    <MonthlyChart {...{ monthDataSource, dayDataSource }} />
                  </div>
                )
              }
            </div>
          ) : (
            <div className='body-right' style={{background: 'rgb(230, 237, 255)', padding: 0}}>
              <div>
                <div className='body-title' style={{background : "#fff"}}>
                  <div>
                    <span className='parent-menu'>
                    {factory}
                    </span>
                  </div>
                  <div  style={{marginRight: "16px"}} className='back-button-wrapper'>
                    <div style={{position: "relative", left : "-16px", display: "inline"}}>
                  {
                    new Date(currentDate).getFullYear() 
                   }
                   <span >年</span>
                   <span >{
                    new Date(currentDate).getMonth() + 1 
                   }</span> 
                    <span >月</span>
                    {
                    new Date(currentDate).getDate() 
                   }
                    <span style={{marginRight: 8}}>日</span>
                    </div>
                    {
                      hasBack ? (<Button
                        className='back-button'
                        onClick={() => backToCurrentMenu(1, currentDate)}
                        type="primary">返回</Button>) : null
                    }
                    <div style={{position: "relative", display: "inline"}}>
                   <span onClick={() => goBack()} style={{ fontWeight: 800, fontSize: 16, left: -24, top: -4, position: "absolute", cursor: "pointer", zIndex: 100000 }}> <LeftOutlined   /></span>
                    <span style={{marginLeft: "-8px"}}>
                    <DatePicker
                      allowClear={false}
                      value={currentDate}
                      onChange={onChange}
                      picker='date' />
                    </span>
                    <span onClick={() =>goForward()} style={{ fontWeight: 800,fontSize: 16, top: -4, position: "absolute",cursor: "pointer", zIndex: 100000 }}> <RightOutlined  /></span>
                    </div>
                  </div>
                </div>
                <div  className='card-container' >
                        <div>
                            <h3>当日合约电价</h3>
                            <div className='text'>
							{ dataByDay && dataByDay.daily_mean_contract_price ? <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(元/MWH)</span> : ""}
							<br />
                            { dataByDay &&  <span style={{ fontSize: 20 }}>{`${dataByDay.daily_mean_contract_price.toFixed(1)}`}</span>}
                            </div>
                            
                        </div>
                        <div>
                          <h3>当日现货电价</h3>
                          <div className='text'>
 							{greatThanToday() ?  ""  : <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(元/MWH)</span> }
							 <br />
                          { greatThanToday() ? 
                              dataByDay &&  dataByDay.daily_mean_spot_price ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_mean_spot_price.toFixed(1)}`}</span> : "/"  :
                              dataByDay &&  dataByDay.daily_mean_spot_price ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_mean_spot_price.toFixed(1)}`}</span> : 0.0 }
                          
                        </div>
                        </div>
                        <div style={{ borderRight: "2px solid #bbb" }}>
                          <h3>当日实际电价</h3>
                          <div className='text'>

							{greatThanToday() ?  ""  : <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(元/MWH)</span> }
                              <br /> 
                          <span style={{color: dataByDay && dataByDay.daily_mean_actual_price > dataByDay.daily_mean_contract_price ? "red" : "inherit"}}> 
                          { greatThanToday() ? 
                                      dataByDay && dataByDay.daily_mean_actual_price ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_mean_actual_price.toFixed(1)}`}</span> : "/" :
                              dataByDay && dataByDay.daily_mean_actual_price ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_mean_actual_price.toFixed(1)}`}</span> : 0.0 }
                        
                         
                           </span> </div>
                        </div>


                        <div>
                          <h3>当日合约电量</h3>
                          <div className='text'>
                          <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(MWH)</span>
                            <br />        
                          { greatThanToday() ? 
                              dataByDay &&  dataByDay.daily_total_contract_mwh ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_total_contract_mwh.toFixed(1)}`}</span> : "/"  :
                              dataByDay ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_total_contract_mwh.toFixed(1)}`}</span> : 0.0 }
                          
                          </div>
                        </div>
                        <div style={{ borderRight: "2px solid #bbb" }}>
                          <h3>当日实际电量</h3>
                          <div className='text'>
                             {!dataByDay || !dataByDay.daily_total_actual_mwh ?  ""  : <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(MWH)</span> } 
     <br />
                          <span > 
                          { 
                                 dailyAcutal() >= 0 ? <span style={{ fontSize: 20 }}> {`${dailyAcutal().toFixed(1)}`}</span> : "/" 
                         
                          }
                           </span></div>
                        </div>

                        <div>
                          <h3>本月现货均价</h3>
                          <div className='text'>
{greatThanToday() ?  ""  :  <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(元/MWH)</span> }
 <br />
                          { greatThanToday() ? 
                                      dataByDay && dataByDay.daily_cum_spot_price ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_cum_spot_price.toFixed(1)}`}</span> : "/" :
                                      dataByDay && dataByDay.daily_cum_spot_price  ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_cum_spot_price.toFixed(1)}`}</span> : <span style={{ fontSize: 20 }}> 0.0 </span> }
                         
                          </div>
                        </div>
                        <div style={{ borderRight: "2px solid #bbb" }}>
                          <h3>本月累计电价</h3>
                          <div className='text'>
  {greatThanToday() ?  ""  : <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(元/MWH)</span> }
 <br />
                          <span style={{color: dataByDay && dataByDay.daily_cum_actual_price > dataByDay.daily_total_contract_mwh ? "red" : "inherit"}}>  
                          { greatThanToday() ? 
                                      dataByDay && dataByDay.daily_cum_actual_price ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_cum_actual_price.toFixed(1)}`}</span> : "/" :
                              dataByDay && dataByDay.daily_cum_actual_price ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_cum_actual_price.toFixed(1)}`}</span> : 0.0 }
                        
                           </span></div>
                        </div>
                        <div >
                          <h3>本月合约电量</h3>
                          <div className='text'>
                          <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(MWH)</span>
<br />
                          { monthDataSource[0] && monthDataSource[0].em_total_contract_mwh && <span style={{ fontSize: 20 }}>{`${monthDataSource[0].em_total_contract_mwh.toFixed(1)}` }</span>}
                          </div>
                        </div>
                        <div>
                          <h3>累计使用电量</h3>
                          <div className='text'>
{greatThanToday() ?  ""  : <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(MWH)</span> }
 <br />
                          { greatThanToday() ? 
                                      dataByDay && dataByDay.daily_cum_actual_mwh ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_cum_actual_mwh.toFixed(2)}`}</span> : "/":
                                      dataByDay && dataByDay.daily_cum_actual_mwh ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_cum_actual_mwh.toFixed(2)}`}</span> : 0.0 }
                       </div>
                        </div>  
                        <div style={{ borderRight: "2px solid #bbb" }}>
                          <h3> 超额/缺额比例</h3>
                          <div className='text'>
                          {greatThanToday() ?  ""  : <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(%)</span> }
                          <br />
                          <span style={{color: dataByDay &&  (dataByDay.daily_cum_mwh_bias_perc < 0.95 || dataByDay.daily_cum_mwh_bias_perc > 1.05) ? "red" : "inherit"}}>
                          { greatThanToday() ? 
                                      dataByDay && dataByDay.daily_cum_mwh_bias_perc ? <span style={{ fontSize: 20 }}> {`${(dataByDay.daily_cum_mwh_bias_perc * 100).toFixed(2)}`}</span> : "/" :
                                      dataByDay && dataByDay.daily_cum_mwh_bias_perc ? <span style={{ fontSize: 20 }}> {`${(dataByDay.daily_cum_mwh_bias_perc * 100).toFixed(2)}`}</span> : 0.0 }
                            
                             
                             </span></div>
                         </div>
                         <div>
                          <h3> 当日电费收益</h3>
                          <div className='text'>
{greatThanToday() ?  ""  : <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(万元)</span>  }
 <br />
                          { greatThanToday()? 
                                      dataByDay && dataByDay.daily_total_elec_profit ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_total_elec_profit.toFixed(2)}`}</span> : "/" :
                                      dataByDay && dataByDay.daily_total_elec_profit ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_total_elec_profit.toFixed(2)}`}</span> : 0.0 }
                             
                             </div>
                         </div>
                        <div>
                        <h3> 本月电费收益</h3>
                        <div className='text'>
 {greatThanToday() ?  ""  : <span style={{color: "rgb(139, 142, 148)", fontWeight: 200, fontSize:13}}>(万元)</span> }
     <br /> 
                        { greatThanToday() ? 
                                      dataByDay && dataByDay.daily_cum_elec_profit ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_cum_elec_profit.toFixed(2)}`}</span> : "/" :
                              dataByDay && dataByDay.daily_cum_elec_profit  ? <span style={{ fontSize: 20 }}> {`${dataByDay.daily_cum_elec_profit.toFixed(2)}`}</span> : <span style={{ fontSize: 20 }}> 0.0 </span> }
                         </div>
                         </div>
                </div>
                <div>
                  <div id='StackedLine' style={{ backgroundColor: "#fff", margin: 24, padding: 24, borderRadius: 5 }}>

                  </div>
                  <div id='percent' style={{ fontWeight: 900, display: "flex", alignItems: "center", backgroundColor: "#fff", margin: 24, padding: 24, borderRadius: 5 }}>
                    <h3 style={{marginBottom: 0, fontWeight: 900}}>
                   <span> 操作建议:  <span > {SuggestionColumns.suggestion}</span></span>
                    
                    </h3>
                  </div>
                  <div style={{ backgroundColor: "#fff", margin: 24, borderRadius: 5, position: "relative" }}>
                    <h3 style={{fontWeight: 900, padding: 20 }}>数据统计 
                    <span style={{fontSize: 14, color: "#666"}}>
                    (电量：MWH，收益：万元， 电价：元/MWH)
                    </span>
                    <a
                      style={{ position: "absolute", zIndex: 1000, right: 24, color: "#000", fontSize: 12}}
                      download={moment(currentDate).format("yyyy-MM-DD") + "日出清结果.xlsx"}
                      onClick={importar}
                      //onclick="return ExcellentExport.excel(this, 'datatable', '现货日出清结果');"
                    >
                      <DownloadOutlined size={14} style={{fontSize: 20}} /> 下载
                    </a>
                    
                     </h3>
                    {
                      dailyPrediction &&
                      <Table id="datatable"
                        size='small'
                        pagination={false}
                        bordered
                        rowClassName={(r, i) => i % 2 === 0 ? "zebra" : "" }
                        dataSource={dailyPredictionAndSum()}
                        columns={DailyChartColumns}
                        scroll={{
                          x: 'max-content'
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
             
            </div>
          )
      }
    </div>
  );
};



export default withRouter(Page);
