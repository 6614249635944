import { clone } from "lodash";
import moment from "moment";

const chartObj = {
  hourly_contract_mwh: {
    yAxisIndex: 0,
    name: "合约电量",
    key: "hourly_contract_mwh",
    unit: "MWH",
    lineStyle: {
      color: "red",
      width: 2,
    },
    symbol: "emptyRect",
    symbolSize: 6,
    itemStyle: {
      normal: {
        color: "red",
        type: "dashed",
      },
    },
  },
  hourly_sys_plan_mwh: {
    yAxisIndex: 0,
    name: "计划电量",
    key: "hourly_sys_plan_mwh",
    unit: "MWH",
    lineStyle: {
      color: "#FF0000",
      width: 2,
      type: "dashed",
    },
    symbol: "emptyTriangle",
    symbolSize: 6,
    itemStyle: {
      normal: {
        color: "#FF0000",
      },
    },
  },
  hourly_actual_mwh: {
    yAxisIndex: 0,
    name: "实际电量",
    key: "hourly_actual_mwh",
    unit: "MWH",
    lineStyle: {
      color: "#0000ff",
      width: 2,
    },
    symbol: "emptyRect",
    symbolSize: 6,
    itemStyle: {
      normal: {
        color: "#0000ff",
      },
    },
  },
  hourly_contract_price: {
    yAxisIndex: 1,
    name: "合约电价",
    key: "hourly_contract_price",
    countKey: "daily_mean_contract_price",
    unit: "元/MWH",
    lineStyle: {
      color: "#9463b7",
      width: 2,
    },
    symbol: "emptyCircle",
    symbolSize: 6,
    itemStyle: {
      normal: {
        color: "#9463b7",
      },
    },
  },
  hourly_spot_price: {
    yAxisIndex: 1,
    name: "现货电价",
    key: "hourly_spot_price",
    countKey: "daily_mean_spot_price",
    unit: "元/MWH",
    lineStyle: {
      color: "#000000",
      width: 2,
    },
    symbol: "emptyDiamond",
    symbolSize: 6,
    itemStyle: {
      normal: {
        color: "#000000",
      },
    },
  },
  hourly_actual_price: {
    yAxisIndex: 1,
    name: "实际电价",
    key: "hourly_actual_price",
    countKey: "daily_mean_actual_price",
    unit: "元/MWH",
    lineStyle: {
      color: "#ffc30e",
      width: 2,
    },
    symbol: "emptyCircle",
    symbolSize: 6,
    itemStyle: {
      normal: {
        color: "#ffc30e",
      },
    },
  },
  hourly_predict_spot_price: {
    yAxisIndex: 1,
    name: "预测电价",
    key: "hourly_predict_spot_price",
    unit: "元/MWH",
    lineStyle: {
      color: "#44ABB9",
      width: 3,
      type: "dashed",
    },

    symbol: "diamond",
    symbolSize: 10,
    itemStyle: {
      normal: {
        color: "#44ABB9",
      },
    },
  },
  price: {
    yAxisIndex: 1,
    name: "估算电价",
    key: "price",
    unit: "元/MWH",
  },
  ref_noprice: {
    yAxisIndex: 1,
    name: "全网实时电能电价",
    unit: "元/MWH",
    key: "ref_noprice",
    symbol: "emptyCircle",
    symbolSize: 6,
    lineStyle: {
      color: "#00cc00",
      width: 2,
    },
    itemStyle: {
      normal: {
        color: "#00cc00",
      },
    },
  },
};

function renderMonthlySummaryCell(text) {
  if (text) {
    text = Math.round(text * 100) / 100;
  }
  return text;
}

function renderDate(text) {
  if (text) {
    text = moment(text).format(`MM月DD日`);
  }
  return text;
}

function renderMonthlySummaryCellPercent(text, record) {
  if (text) {
    text = `${Math.round(text * 10000) / 100}%`;
  }
  return text;
}

const formatTimex = (item) => {
  let timex = item.timex;
  let [time] = (timex || "").split(":");
  time = Number(time);
  item.hour = time;
  item.formatTimex = `${time - 1}-${time}`;
};

const menuList = [
  {
    id: 2,
    name: "用电计划",
  },
  {
    id: 1,
    name: "用电统计",
  },
];

const getColumns = () => {
  return [
    {
      title: "月度用电分析",
      className: "elec-table-header",
      key: "key",
      dataIndex: "key",
      align: "center",
      children: [
        {
          title: "统计截止日期",
          dataIndex: "datetime",
          key: "datetime",
          className: "elec-table-title fz16",
          align: "center",
          render: renderDate,
          children: [],
        },
        {
          title: (
            <span>
              合约电量 <br /> (MWH){" "}
            </span>
          ),
          dataIndex: "em_total_contract_mwh",
          key: "em_total_contract_mwh",
          className: "elec-table-title fz16",
          align: "center",
          children: [],
          render: (t, r) => {
            if (r["em_total_contract_mwh"]) {
              return r["em_total_contract_mwh"].toFixed(0);
            }
          },
          // children: [
          //   {
          //     title: '100%',
          //     className: 'elec-tabel-cell fz16',
          //     key: 'em_total_contract_mwh',
          //     dataIndex: 'em_total_contract_mwh',
          //     render: renderMonthlySummaryCell,
          //     align: 'center'
          //   }
          // ]
        },
        {
          title: (
            <span>
              累计电量 <br /> (MWH){" "}
            </span>
          ),
          dataIndex: "daily_cum_actual_mwh",
          key: "daily_cum_actual_mwh",
          isPercent: true,
          className: "elec-table-title fz16",
          align: "center",
          children: [],
          render: (t, r) => {
            if (r["daily_cum_actual_mwh"]) {
              return r["daily_cum_actual_mwh"].toFixed(0);
            }
          },
        },

        {
          title: (
            <span>
              超额/缺额 <br /> (%){" "}
            </span>
          ),
          dataIndex: "daily_cum_mwh_bias_perc",
          key: "daily_cum_mwh_bias_perc",
          isPercent: true,
          className: "elec-table-title fz16",
          align: "center",
          children: [],
          render: (t, r) => {
            if (r["daily_cum_mwh_bias_perc"]) {
              return (r["daily_cum_mwh_bias_perc"] * 100).toFixed(2) + "%";
            }
          },
        },
        {
          title: (
            <span>
              超额/缺额 <br /> (MWH){" "}
            </span>
          ),
          dataIndex: "daily_cum_mwh_bias",
          key: "daily_cum_mwh_bias",
          isPercent: true,
          className: "elec-table-title fz16",
          align: "center",
          children: [],
          render: (t, r) => {
            if (r["daily_cum_mwh_bias"]) {
              return r["daily_cum_mwh_bias"].toFixed(1);
            }
          },
        },
        {
          title: (
            <span>
              合约电价 <br />
              （元/MWH){" "}
            </span>
          ),
          dataIndex: "em_mean_contract_price",
          key: "em_mean_contract_price",
          isPercent: true,
          className: "elec-table-title fz16",
          align: "center",
          children: [],
          render: (t, r) => {
            if (r["em_mean_contract_price"]) {
              return r["em_mean_contract_price"].toFixed(1);
            }
          },
        },
        {
          title: (
            <span>
              累计电价 <br />
              （元/MWH){" "}
            </span>
          ),
          dataIndex: "daily_cum_actual_price",
          key: "daily_cum_actual_price",
          isPercent: true,
          className: "elec-table-title fz16",
          align: "center",
          children: [],
          render: (t, r) => {
            if (r["daily_cum_actual_price"]) {
              return r["daily_cum_actual_price"].toFixed(1);
            }
          },
        },
        {
          title: (
            <span>
              平均现货电价 <br />
              （元/MWH){" "}
            </span>
          ),
          dataIndex: "daily_cum_spot_price",
          key: "daily_cum_spot_price",
          isPercent: true,
          className: "elec-table-title fz16",
          align: "center",
          children: [],
          render: (t, r) => {
            if (r["daily_cum_spot_price"]) {
              return r["daily_cum_spot_price"].toFixed(1);
            }
          },
        },
        {
          title: (
            <span>
              收益 <br />
              （万元){" "}
            </span>
          ),
          dataIndex: "total_elec_profit",
          key: "total_elec_profit",
          isPercent: true,
          className: "elec-table-title fz16",
          align: "center",
          children: [],
          render: (t, r) => {
            if (r["total_elec_profit"]) {
              let tempData = r["total_elec_profit"].toFixed(1);
              return tempData;
            }
          },
        },
        // {
        //   title: '剩余合约电量',
        //   isPercent: true,
        //   dataIndex: 'daily_remain_contract_mwh_perc',
        //   key: 'daily_remain_contract_mwh_perc',
        //   className: 'elec-table-title fz16',
        //   align: 'center',
        //   children: [
        //     {
        //       title: '',
        //       key: 'daily_remain_contract_mwh',
        //       dataIndex: 'daily_remain_contract_mwh',
        //       className: 'elec-tabel-cell fz16',
        //       render: renderMonthlySummaryCell,
        //       align: 'center'
        //     }
        //   ]
        // },
        // {
        //   title: '已产生偏差',
        //   isPercent: true,
        //   dataIndex: 'daily_cum_mwh_bias_perc',
        //   key: 'daily_cum_mwh_bias_perc',
        //   className: 'elec-table-title fz16',
        //   align: 'center',
        //   children: [
        //     {
        //       title: '',
        //       key: 'daily_cum_mwh_bias',
        //       dataIndex: 'daily_cum_mwh_bias',
        //       className: 'elec-tabel-cell fz16',
        //       render: renderMonthlySummaryCell,
        //       align: 'center'
        //     }
        //   ]
        // },
        // {
        //   title: '电价',
        //   dataIndex: 'age',
        //   key: 'age',
        //   className: 'elec-table-title fz16',
        //   align: 'center',
        //   children: [
        //     {
        //       title: '月度合约均价',
        //       dataIndex: 'em_mean_contract_price',
        //       key: 'em_mean_contract_price',
        //       className: 'elec-tabel-cell fz16',
        //       render: renderMonthlySummaryCell,
        //       align: 'center'
        //     },
        //     {
        //       title: '当前累计电价',
        //       dataIndex: 'daily_cum_actual_price',
        //       key: 'daily_cum_actual_price',
        //       className: 'elec-tabel-cell fz16',
        //       render: renderMonthlySummaryCell,
        //       align: 'center'
        //     },
        //     {
        //       title: '平均现货电价',
        //       dataIndex: 'daily_cum_spot_price',
        //       key: 'daily_cum_spot_price',
        //       className: 'elec-tabel-cell fz16',
        //       render: renderMonthlySummaryCell,
        //       align: 'center'
        //     }
        //   ]
        // },
      ],
    },
  ];
};

const getDayColumns = (setCurrentDate, setCurrentMenu, getChartData) => {
  return [
    {
      title: "每日用电统计",
      className: "elec-table-header",
      key: "key",
      children: [
        {
          title: "序列",
          dataIndex: "em_xday",
          key: "em_xday",
          className: "elec-table-title fz16",
          render: (text, record, index) => {
            return `${text}日`;
          },
          align: "center",
        },
        {
          title: "日期",
          dataIndex: "datetime",
          key: "datetime",
          className: "elec-table-title fz16",
          render: renderDate,
          align: "center",
        },
        {
          title: "统计状态",
          dataIndex: "daily_state",
          key: "daily_state",
          className: "elec-table-title fz16",
          align: "center",
        },
        {
          title: "合约电量",
          dataIndex: "daily_total_contract_mwh",
          key: "daily_total_contract_mwh",
          className: "elec-table-title fz16",
          render: renderMonthlySummaryCell,
          align: "center",
        },
        {
          title: "实际电量",
          dataIndex: "daily_total_actual_mwh",
          key: "daily_total_actual_mwh",
          className: "elec-table-title fz16",
          render: renderMonthlySummaryCell,
          align: "center",
        },
        {
          title: "电量偏差",
          dataIndex: "daily_plan_mwh_dev_perc",
          key: "daily_plan_mwh_dev_perc",
          className: "elec-table-title fz16",
          render: renderMonthlySummaryCellPercent,
          align: "center",
        },
        {
          title: "合约电价",
          dataIndex: "daily_mean_contract_price",
          key: "daily_mean_contract_price",
          className: "elec-table-title fz16",
          render: renderMonthlySummaryCell,
          align: "center",
        },

        {
          title: "现货电价",
          dataIndex: "daily_mean_spot_price",
          key: "daily_mean_spot_price",
          className: "elec-table-title fz16",
          render: renderMonthlySummaryCell,
          align: "center",
        },

        {
          title: "实际电价",
          dataIndex: "daily_mean_actual_price",
          key: "daily_mean_actual_price",
          className: "elec-table-title fz16",
          render: renderMonthlySummaryCell,
          align: "center",
        },

        {
          title: "月累计电价",
          dataIndex: "daily_cum_actual_price",
          key: "daily_cum_actual_price",
          className: "elec-table-title fz16",
          render: renderMonthlySummaryCell,
          align: "center",
        },
        {
          title: "日收益",
          dataIndex: "daily_total_elec_profit",
          key: "daily_total_elec_profit",
          className: "elec-table-title fz16",
          render: renderMonthlySummaryCell,
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          className: "elec-table-title fz16",
          align: "center",
          render: (text, record) => {
            return (
              <a
                className="pointer"
                onClick={() => {
                  let time = moment(record.datetime);
                  setCurrentDate(time);
                  setCurrentMenu(2);

                  getChartData(time);
                }}
              >
                查看
              </a>
            );
          },
        },
      ],
    },
  ];
};

const DailyChartDefaultColumns = [
  {
    title: "",
    dataIndex: "em_xday",
    key: "em_xday",
    colSpan: 2,
    width: 30,
    className: "elec-table-title fz16",
    render: (text, record, index) => {
      return index + 1;
    },
    align: "center",
    fixed: "left",
  },
  {
    title: "name",
    colSpan: 0,
    width: 80,
    dataIndex: "name",
    key: "name",
    align: "center",
    fixed: "left",
  },
  // {
  //   title: 'unit',
  //   colSpan: 0,
  //   width: 80,
  //   dataIndex: 'unit',
  //   key: 'unit',
  //   align: 'center',
  //   fixed: 'left'
  // },
];

const defaultSuggestionColumns = [
  {
    title: "操作建议",
    dataIndex: "em_xday",
    key: "em_xday",
    colSpan: 3,
    width: 30,
    className: "elec-tabel-cell fz16",
    render: (text, record, index) => {
      return index + 1;
    },
    align: "center",
  },
  {
    title: "name",
    colSpan: 0,
    width: 80,
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "unit",
    colSpan: 0,
    width: 80,
    dataIndex: "unit",
    key: "unit",
    align: "center",
  },
];

const get_number_value = function (record, key, digit) {
  // let year = (new Date()).getFullYear();
  // let month = (new Date()).getMonth();
  // let day = (new Date()).getDay() ;
  // let datetime = new Date(record.datetime);
  let val = record[key];
  // if (datetime.getFullYear() === year &&
  //     datetime.getMonth() === month &&
  //     datetime.getDay() === day ) {
  //     if (!val) {
  //       return "/"
  //     }
  // } else {
  //   if (!val) {
  //     return 0
  //   }
  // }
  if (val) {
    return val.toFixed(digit);
  } else {
    return "";
  }
};

const get_number_value_no_time = function (record, key, digit) {
  let val = record[key];
  if (val === 0) {
    return 0;
  } else if (!val) {
    return "";
  }
  return val.toFixed(digit);
};

const get_number_value_by_time = function (
  record,
  key,
  digit,
  returnZero = true
) {
  let now = new Date();
  let datetime = new Date(record.datetime);
  if (!record.timex) {
    return record[key];
  }
  let val = record[key];
  if (now < datetime) {
    if (!val) {
      return "";
    }
  } else {
    if (val === 0 || !val) {
      if (returnZero) {
        return 0;
      } else {
        return "";
      }
    }
  }
  return val.toFixed(digit);
};

const DailyPredictionColumns = [
  {
    title: "日期",
    // dataIndex: 'datetime',
    colSpan: 1,
    width: 30,
    className: "elec-table-title fz16",
    align: "center",
    fixed: "left",
    render: (text, record, index) => {
      return record["datetime"]
        ? moment(record["datetime"]).format("YYYY-MM-DD")
        : "";
    },
  },

  {
    title: "时刻",
    dataIndex: "timex",
    colSpan: 1,
    width: 30,
    className: "elec-table-title fz16",
    align: "center",
    fixed: "left",
    render: (text, record, index) => {
      return record["timex"]
        ? moment(record["datetime"]).format("HH:mm") + "-" + record["timex"]
        : "合计:";
    },
    // onCell: (record, index) => {
    //   return {
    //     colSpan: record["timex"] ? 1 : 2,
    //   };
    // },
  },

  {
    title: "中长期合同电量",
    dataIndex: "hourly_contract_mwh",
    colSpan: 1,
    width: 30,
    className: "elec-table-title fz16",
    align: "center",
    fixed: "left",
    render: (text, record, index) => {
      return get_number_value(record, "hourly_contract_mwh", 2);
    },
  },
  {
    title: "中长期合同电价",
    dataIndex: "hourly_contract_price",
    colSpan: 1,
    width: 30,
    className: "elec-table-title fz16",
    align: "center",
    fixed: "left",
    render: (text, record, index) => {
      return get_number_value(record, "hourly_contract_price", 2);
    },
  },
  {
    title: "现货出清电价",
    dataIndex: "hourly_spot_price",
    colSpan: 1,
    width: 30,
    className: "elec-table-title fz16",
    align: "center",
    fixed: "left",
    render: (text, record, index) => {
      return get_number_value_by_time(record, "hourly_spot_price", 2, false);
    },
  },
  {
    title: "实际使用电量",
    dataIndex: "hourly_actual_mwh",
    colSpan: 1,
    width: 30,
    className: "elec-table-title fz16",
    align: "center",
    fixed: "left",
    render: (text, record, index) => {
      return get_number_value_by_time(record, "hourly_actual_mwh", 2);
    },
  },
  {
    title: "日前预测电价",
    dataIndex: "hourly_predict_spot_price",
    colSpan: 1,
    width: 30,
    className: "elec-table-title fz16",
    align: "center",
    fixed: "left",
    render: (text, record, index) => {
      return get_number_value(record, "hourly_predict_spot_price", 2);
    },
  },
  {
    title: "实时全网实时电能电价",
    dataIndex: "REF_NODE_SPOT",
    colSpan: 1,
    width: 30,
    className: "elec-table-title fz16",
    align: "center",
    fixed: "left",
    render: (text, record, index) => {
      return get_number_value(record, "REF_NODE_SPOT", 2);
    },
  },
  {
    title: "日前计划电量",
    dataIndex: "hourly_sys_plan_mwh",
    colSpan: 1,
    width: 30,
    className: "elec-table-title fz16",
    align: "center",
    fixed: "left",
    render: (text, record, index) => {
      return get_number_value(record, "hourly_sys_plan_mwh", 2);
    },
  },
];

export {
  chartObj,
  renderMonthlySummaryCell,
  menuList,
  DailyPredictionColumns,
  getColumns,
  renderMonthlySummaryCellPercent,
  formatTimex,
  getDayColumns,
  DailyChartDefaultColumns,
  defaultSuggestionColumns,
  renderDate,
};
