import React, { useCallback, useEffect, useMemo, useState } from "react";
import style from "./index.module.scss";
import { withRouter } from "react-router-dom";
import { Table, DatePicker, Button, Space } from "antd";
import { get, post } from "common/utils.js";
import moment from "moment";
import * as echarts from "echarts~5/lib/echarts";
import DomResize from "components/DomResize";
// // 引入柱状图
// import 'echarts/lib/chart/bar';
// // 引入提示框和标题组件
// import 'echarts/lib/component/tooltip';
// import 'echarts/lib/component/title';

// import echarts from 'echarts';
import { getCachedUserInfo } from "common/utils.js";
import {
  chartObj,
  renderMonthlySummaryCell,
  menuList,
  getColumns,
  renderMonthlySummaryCellPercent,
  formatTimex,
  getDayColumns,
  DailyChartDefaultColumns,
  defaultSuggestionColumns,
} from "./utils";
import MonthlyChart from "./MonthlyChart";

let timeRange = [];
for (let index = 0; index < 24; index++) {
  timeRange.push(`${index + 1}:00`);
}

const Page = (props) => {
  useEffect(() => {
    get(
      process.env.REACT_APP_SERVER_PATH +
        (props.match.url.indexOf("elestat") > -1
          ? "api/auth/checkpoint/estat"
          : "api/auth/checkpoint/eplan")
    );
  }, []);

  // 用电监测-用电计划
  let defaultCurrentMenu = 2;

  if (props.match.url.indexOf("elestat") > -1) {
    defaultCurrentMenu = 1;
  }
  let { factoryId } = props.match.params;

  const [hasAccess, setHasAccess] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(defaultCurrentMenu);
  const [currentDate, setCurrentDate] = useState(moment());
  const [hasBack, setHasBack] = useState(defaultCurrentMenu == 1);

  const [monthColumns, setMonthColumns] = useState(() => getColumns());
  const [monthDataSource, setMonthDataSource] = useState([]);

  const [dayDataSource, setDayDataSource] = useState([]);
  const [DailyChartDataSource, setDailyChartDataSource] = useState([]);
  const [DailyChartColumns, setDailyChartColumns] = useState([
    ...DailyChartDefaultColumns,
  ]);
  const [ProductionPlansDataSource, setProductionPlansDataSource] = useState(
    []
  );
  const [SuggestionColumns, setSuggestionColumns] = useState([
    ...defaultSuggestionColumns,
  ]);
  const [factory, setFactory] = useState("");

  const initData = (date) => {
    if (currentMenu === 1) {
      GetDailySummary(date);
      // GetMonthlySummary(date);
    } else {
      getChartData(date);
    }
  };
  const backToCurrentMenu = (CurrentMenu, time) => {
    setCurrentDate(time);
    setCurrentMenu(CurrentMenu);
    GetDailySummary(time);
    // GetMonthlySummary(time);
  };

  function GetMonthlySummary(date, tempDayDataSource) {
    const year = moment(date).year();
    const month = moment(date).month() + 1;
    get("https://hq01.quantile.tech:5000/api/Trader/GetMonthlySummary", {
      factoryId: factoryId,
      year,
      month,
    })
      .then((res) => {
        let data = res.data;

        const columns = getColumns();

        columns.forEach((header) => {
          header.children.forEach((child) => {
            if (child.children.length === 1) {
              if (child.key !== "nokey") {
                if (child.isPercent) {
                  child.children[0].title = renderMonthlySummaryCellPercent(
                    data[child.key]
                  );
                } else {
                  child.children[0].title = renderMonthlySummaryCell(
                    data[child.key]
                  );
                }
              }
            } else {
            }
          });
        });

        if (tempDayDataSource && tempDayDataSource.length > 0) {
          data.total_elec_profit = tempDayDataSource.reduce((sum, item) => {
            sum += item.daily_total_elec_profit;
            return sum;
          }, 0);
        }

        setMonthColumns(columns);
        setMonthDataSource([{ ...data, age: 1, key: 1 }]);
      })
      .catch(() => {
        const columns = getColumns();
        columns.forEach((header) => {
          header.children.forEach((child) => {
            if (child.children.length === 1) {
              if (child.key !== "nokey") {
                if (child.isPercent) {
                  child.children[0].title = null;
                } else {
                  child.children[0].title = null;
                }
              }
            } else {
            }
          });
        });

        setMonthColumns(columns);
        setMonthDataSource([]);
      });
  }
  useEffect(() => {
    getAccess();

    get(
      "https://hq01.quantile.tech:5000/api/Trader/GetCustomerById?factoryId=" +
        factoryId
    ).then((res) => {
      setFactory(res.data.name);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function GetDailySummary(date) {
    const year = moment(date).year();
    const month = moment(date).month() + 1;

    get("https://hq01.quantile.tech:5000/api/Trader/GetDailySummary", {
      factoryId: factoryId,
      year,
      month,
    })
      .then((res) => {
        let data = res.data;
        data.forEach((item) => {
          item.key = item.em_xday;
        });
        setDayDataSource(data);
        GetMonthlySummary(date, data);
      })
      .catch(() => {
        setDayDataSource([]);
      });
  }

  useEffect(() => {
    setDayDataSource(null);
    setMonthDataSource(null);
  }, [currentDate]);

  useEffect(() => {
    // console.log(2222);
    // if (
    //   monthDataSource &&
    //   monthDataSource.length > 0 &&
    //   dayDataSource &&
    //   dayDataSource.length > 0 &&
    //   !monthDataSource[0].hasOwnProperty("total_elec_profit")
    // ) {
    //   monthDataSource[0].total_elec_profit = dayDataSource.reduce(
    //     (sum, item) => {
    //       sum += item.daily_total_elec_profit;
    //       return sum;
    //     },
    //     0
    //   );
    //   setMonthDataSource([...monthDataSource]);
    // }
  }, [monthDataSource, dayDataSource]);

  const getChartData = (date) => {
    GetDailyChart(date);
    GetDailyTrendForecast(date);

    GetSuggestion(date);
    GetProductionPlansByFactoryIdAndDate(date);
  };

  const onChange = (data) => {
    setCurrentDate(data);
    initData(data);
  };

  const getNotNullData = (list, key, dataDate) => {
    let newList = list.map((data) => data[key]);

    if (key == "hourly_actual_mwh") {
      let currentDate = moment().format("YYYYMMDD");
      dataDate = moment(dataDate).format("YYYYMMDD");
      // console.log('currentDate', currentDate, dataDate);
      if (currentDate == dataDate) {
        // console.log('newList', newList);
        let currentHour = moment().hour() + 1;
        newList = list.filter((item) => {
          return item.hour < currentHour || item[key];
        });
        newList = newList.map((data) => data[key]);
      } else if (currentDate < dataDate) {
        newList = [];
      }
    }

    if (key == "hourly_actual_price") {
      let currentDate = moment().format("YYYYMMDD");
      dataDate = moment(dataDate).format("YYYYMMDD");

      if (currentDate == dataDate) {
        newList = [];
      } else if (currentDate < dataDate) {
        newList = [];
      }
    }
    // let hasValue = newList.some(item => item)
    // if(hasValue){
    //     newList = newList.filter(item => item)
    // }

    return newList;
  };
  const setStackedLine = (list, currentDate, bckList) => {
    // console.log(bckList)
    var chartDom = document.getElementById("StackedLine");
    var myChart = echarts.init(chartDom);
    var option;
    // console.log('currentDate', currentDate);
    option = {
      title: {
        text: `每日用电计划与用电结算(${moment(currentDate).format(
          "MM月DD日"
        )})`,
        left: "center",
        top: 0,
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        left: "left",
        top: "center",
        orient: "vertical",
        // top: 11,
        itemHeight: 10,
        itemGap: 50,

        data: [
          "合约电量",
          "计划电量",
          "实际电量",
          "合约电价",
          "现货电价",
          "实际电价",
          "预测电价",
          "临界电价",
        ],
        // itemStyle: {
        //     borderType: 'dashed'
        // }
        // color: [
        //     'red',
        //     'red'
        // ]
      },
      grid: {
        left: 170,
        right: 0,
        bottom: "10%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "category",
        nameLocation: "center",
        boundaryGap: false,
        splitLine: {
          show: false,
        },
        show: true,
        // axisLabel: { interval: 0 },
        data: list.map((item) => item.formatTimex),
      },
      yAxis: [
        {
          type: "value",
          name: "电量,MWH",
          splitLine: {
            show: false,
          },
        },
        {
          type: "value",
          name: "电价,元/MWH",
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        chartObj.hourly_contract_mwh,
        chartObj.hourly_sys_plan_mwh,
        chartObj.hourly_actual_mwh,
        chartObj.hourly_contract_price,
        chartObj.hourly_spot_price,
        chartObj.hourly_actual_price,
        chartObj.hourly_predict_spot_price,
      ]
        .map((item) => {
          return {
            name: item.name,
            type: "line",
            yAxisIndex: item.yAxisIndex,
            data: getNotNullData(list, item.key, currentDate),
            lineStyle: item.lineStyle,
            symbol: item.symbol,
            symbolSize: item.symbolSize || 6,
            itemStyle: item.itemStyle,
          };
        })
        .concat(
          (bckList ?? []).length > 0
            ? [
                {
                  name: "临界电价",
                  type: "line",
                  yAxisIndex: 1,
                  data: bckList,
                  lineStyle: {
                    // width: 0
                  },
                  symbol: "none",
                  itemStyle: {
                    color: "rgba(252,221,222,0.5)",
                  },
                  emphasis: {
                    disabled: true,
                  },
                  areaStyle: {
                    normal: {
                      origin: "end",
                      color: "rgba(252,221,222,0.5)",
                    },
                  },
                },
              ]
            : []
        ),
    };

    option && myChart.setOption(option);
  };

  const setPercentBar = (list) => {
    var chartDom = document.getElementById("percent");
    var myChart = echarts.init(chartDom);

    // console.log('list', list, list.map(item => -item.lt_contract_price_prob));

    let option = {
      tooltip: {},
      legend: {
        show: true,
        left: "left",
        top: "center",
        orient: "vertical",
        // top: 11,
        zlevel: 1,

        data: ["高于合约电价概率", "低于合约电价概率"],
      },
      xAxis: [
        {
          gridIndex: 0,
          show: false,

          splitLine: {
            show: false,
          },
          axisLabel: { interval: 0 },
          data: list.map((item) => item.timex),
        },
        {
          // show:false,
          gridIndex: 1,
          // show:false,
          // inverse: true,

          data: list.map((item) => item.timex),
          position: "top",

          axisLine: {
            onZero: true,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          zlevel: 1,
        },
      ],
      yAxis: [
        {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: true,
          },
          position: "left",
          splitArea: {
            show: false,
          },
          show: false,
          interval: 0.3,
          max: 100,
          min: 0,
        },
        {
          gridIndex: 1,
          position: "left",
          splitArea: {
            show: false,
          },
          show: false,
          interval: 0.3,
          max: 0,
          min: -100,
        },
      ],
      grid: [
        {
          top: "20%",
          bottom: "50.5%",
          left: 170,
          right: 0,
          containLabel: true,
        },
        {
          top: "50%",
          bottom: "20%",
          left: 170,
          right: 0,
          containLabel: true,
        },
      ],
      color: ["#0070c0", "#ffc000"],
      series: [
        {
          xAxisIndex: 0,
          yAxisIndex: 0,
          barWidth: "30%",
          name: "高于合约电价概率",
          type: "bar",
          emphasis: {
            itemStyle: {
              barBorderWidth: 1,
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowColor: "rgba(0,0,0,0.5)",
            },
          },

          itemStyle: {
            normal: {
              label: {
                show: true,

                position: "top",
                formatter: (item) => {
                  return `${item.value}%`;
                },
              },
            },
          },
          tooltip: {
            valueFormatter: (value) => {
              return `${value}%`;
            },
          },
          data: list.map((item) => item.ht_contract_price_prob),
        },
        {
          xAxisIndex: 1,
          yAxisIndex: 1,
          name: "低于合约电价概率",
          type: "bar",
          barWidth: "30%",
          itemStyle: {
            normal: {
              label: {
                show: true,

                position: "bottom",
                formatter: (item) => {
                  return `${item.value * -1}%`;
                },
              },
            },
          },
          tooltip: {
            valueFormatter: (value) => {
              return `${value * -1}%`;
            },
          },
          data: [...list.map((item) => -item.lt_contract_price_prob)],
        },
      ],
    };

    option && myChart.setOption(option);
  };
  const setPercentTwoBar = (list) => {
    var chartDom = document.getElementById("percent1");
    var myChart = echarts.init(chartDom);

    let option = {
      // title: {
      //     text: '景蕾每日用电计划与用电结算',
      //     left: 'center',
      //     top: 0,
      // },
      // barWidth: '50%',
      // backgroundColor: '#eee',
      // toolbox: {
      //     feature: {
      //         magicType: {
      //             type: ['stack', 'tiled']
      //         },
      //     }
      // },
      tooltip: {},
      legend: {
        show: true,
        left: "left",
        top: "center",
        orient: "vertical",
        // top: 11,
        zlevel: 1,

        data: ["高于日均电价概率", "低于日均电价概率"],
      },
      xAxis: [
        {
          gridIndex: 0,
          show: false,

          splitLine: {
            show: false,
          },
          axisLabel: { interval: 0 },
          data: list.map((item) => item.timex),
        },
        {
          // show:false,
          gridIndex: 1,

          data: list.map((item) => item.timex),
          position: "top",

          axisLine: {
            onZero: true,
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          zlevel: 1,
        },
      ],
      yAxis: [
        {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: true,
          },
          position: "left",
          splitArea: {
            show: false,
          },
          show: false,
          interval: 0.3,
          max: 100,
          min: 0,
        },
        {
          gridIndex: 1,
          position: "left",
          splitArea: {
            show: false,
          },
          show: false,
          interval: 0.3,
          max: 0,
          min: -100,
        },
      ],
      grid: [
        {
          top: "20%",
          bottom: "50.5%",
          left: 170,
          right: 0,
          containLabel: true,
        },
        {
          top: "50%",
          bottom: "20%",
          left: 170,
          right: 0,
          containLabel: true,
        },
      ],
      color: ["#0070c0", "#ffc000"],
      series: [
        {
          xAxisIndex: 0,
          yAxisIndex: 0,
          name: "高于日均电价概率",
          type: "bar",
          emphasis: {
            itemStyle: {
              barBorderWidth: 1,
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowColor: "rgba(0,0,0,0.5)",
            },
          },
          barWidth: "30%",
          itemStyle: {
            normal: {
              label: {
                show: true,

                position: "top",
                formatter: (item) => {
                  return `${item.value}%`;
                },
              },
            },
          },
          tooltip: {
            valueFormatter: (value) => {
              return `${value}%`;
            },
          },
          data: list.map((item) => item.ht_target_price_prob),
        },
        {
          xAxisIndex: 1,
          yAxisIndex: 1,
          name: "低于日均电价概率",
          type: "bar",
          barWidth: "30%",
          itemStyle: {
            normal: {
              label: {
                show: true,

                position: "bottom",
                formatter: (item) => {
                  return `${item.value * -1}%`;
                },
              },
            },
          },

          data: list.map((item) => -item.lt_target_price_prob),
          tooltip: {
            valueFormatter: (value) => `${value}%`,
          },
        },
      ],
    };

    option && myChart.setOption(option);
  };

  const GetDailyChart = (date) => {
    const dateTime = moment(date).format("YYYY-MM-DD");
    const year = moment(date).year();
    const month = moment(date).month() + 1;
    const em_xday = moment(date).date();
    Promise.all([
      get("https://hq01.quantile.tech:5000/api/Trader/GetDailyChart", {
        factoryId: factoryId,
        dateTime,
      }),
      get("https://hq01.quantile.tech:5000/api/Trader/GetDailySummary", {
        factoryId: factoryId,
        year,
        month,
      }),
      post("https://epublic.quantile.tech/ele_api/get_critical_eprice", {
        fid: parseInt(factoryId),
        date: dateTime,
      }),
    ])
      .then(([DailyChart, DailySummary, bckData]) => {
        let bckList = null;
        if (bckData?.data?.errcode === 0) {
          bckList = (bckData?.data?.results ?? []).map(
            ({ hourly_critical_eprice_ulim }) =>
              typeof hourly_critical_eprice_ulim === "number"
                ? Math.round(hourly_critical_eprice_ulim * 100) / 100
                : null
          );
        }
        let data = DailyChart.data;

        let DailyChartColumns = [...DailyChartDefaultColumns];

        let summary = DailySummary.data.find((item) => item.em_xday == em_xday);
        // console.log('summary', summary);

        let DailyChartDataSource = [
          chartObj.hourly_contract_mwh,
          chartObj.hourly_contract_price,
          chartObj.hourly_spot_price,
          chartObj.hourly_actual_mwh,
          chartObj.hourly_actual_price,
          chartObj.hourly_predict_spot_price,
          chartObj.hourly_sys_plan_mwh,
          // chartObj.price,
        ];
        DailyChartDataSource.forEach((item) => {
          item.count = 0;
        });
        data.forEach((item) => {
          formatTimex(item);

          DailyChartColumns.push({
            title: item.formatTimex,
            dataIndex: item.timex,
            className: "min-table-cell",
            key: item.timex,
            align: "center",
          });

          DailyChartDataSource.forEach((source) => {
            let value = item[source.key] || 0;
            item[source.key] = renderMonthlySummaryCell(value);
            source[item.timex] = item[source.key];

            source.count += item[source.key];
          });
        });
        setStackedLine(data, date, bckList);

        DailyChartColumns.push({
          title: "合计",
          dataIndex: "count",
          className: "min-table-cell",
          key: "count",
          align: "center",
        });
        DailyChartDataSource.forEach((item) => {
          if (item.countKey) {
            item.count = renderMonthlySummaryCell(summary[item.countKey]);
          } else {
            if (item.yAxisIndex) {
              item.count = renderMonthlySummaryCell(item.count / 24);
            } else {
              item.count = renderMonthlySummaryCell(item.count);
            }
          }
        });
        setDailyChartColumns(DailyChartColumns);
        setDailyChartDataSource(
          (DailyChartDataSource ?? []).map((item, index) => ({
            ...item,
            key: `${index}`,
          }))
        );
      })
      .catch(() => {
        setStackedLine([], date, []);
        setDailyChartDataSource([]);
      });
  };
  const GetDailyTrendForecast = (date) => {
    const dateTime = moment(date).format("YYYY-MM-DD");
    get("https://hq01.quantile.tech:5000/api/Trader/GetDailyTrendForecast", {
      factoryId: factoryId,
      dateTime,
    })
      .then((res) => {
        let data = res.data;
        data.forEach((item) => {
          item.ht_contract_price_prob = renderMonthlySummaryCell(
            item.ht_contract_price_prob * 100
          );
          item.lt_contract_price_prob = renderMonthlySummaryCell(
            item.lt_contract_price_prob * 100
          );
          item.ht_target_price_prob = renderMonthlySummaryCell(
            item.ht_target_price_prob * 100
          );
          item.lt_target_price_prob = renderMonthlySummaryCell(
            item.lt_target_price_prob * 100
          );
        });
        setPercentBar(data);
        setPercentTwoBar(data);
        // setDayDataSource(data)
      })
      .catch(() => {
        setPercentBar([]);
        setPercentTwoBar([]);
      });
  };

  const GetSuggestion = (date) => {
    const dateTime = moment(date).format("YYYY-MM-DD");
    get("https://hq01.quantile.tech:5000/api/Trader/GetSuggestion", {
      factoryId: factoryId,
      dateTime,
    })
      .then((res) => {
        let data = res && res.data;

        let SuggestionColumns = [...defaultSuggestionColumns];

        let suggestion = "";
        if (data && data.suggestion) {
          suggestion = data.suggestion;
        }
        SuggestionColumns.push({
          title: suggestion,
          dataIndex: "key",
          key: "key",
          className: "elec-tabel-cell red fz16",
          align: "center",
        });

        setSuggestionColumns(SuggestionColumns);
      })
      .catch(() => {
        setSuggestionColumns([]);
      });
  };

  const GetProductionPlansByFactoryIdAndDate = (date) => {
    const dateTime = moment(date).format("YYYY-MM-DD");
    get(
      "https://hq01.quantile.tech:5000/api/Trader/GetProductionPlansByFactoryIdAndDate",
      {
        factoryId: factoryId,
        dateTime,
      }
    )
      .then((res) => {
        let data = res.data;

        let ProductionPlansDataObj = {};
        let ProductionPlansDataSource = [];
        data.forEach((item, index) => {
          let hourly_sys_plan_mwh = renderMonthlySummaryCell(
            item.hourly_sys_plan_mwh
          );
          if (item.sys_id == 0) {
            hourly_sys_plan_mwh = renderMonthlySummaryCell(
              hourly_sys_plan_mwh * 1000
            );
          }
          if (ProductionPlansDataObj[item.sys_id]) {
            ProductionPlansDataObj[item.sys_id][item.timex] =
              hourly_sys_plan_mwh;
            ProductionPlansDataObj[item.sys_id].count += hourly_sys_plan_mwh;
          } else {
            ProductionPlansDataObj[item.sys_id] = {
              ...item,
              name: item.sys_name + "kW",
              unit: "kW",
              count: hourly_sys_plan_mwh,
              [item.timex]: hourly_sys_plan_mwh,
              align: "center",
            };
            ProductionPlansDataSource.push(ProductionPlansDataObj[item.sys_id]);
          }
        });

        ProductionPlansDataSource.forEach((item) => {
          item.count = renderMonthlySummaryCell(item.count / 24);
        });

        setProductionPlansDataSource(
          (ProductionPlansDataSource ?? []).map((item, index) => ({
            ...item,
            key: `${index}`,
          }))
        );

        // setDayDataSource(data)
      })
      .catch(() => {
        setProductionPlansDataSource([]);
      });
  };

  const getAccess = async () => {
    let res = await post(
      process.env.REACT_APP_SERVER_PATH + "api/auth/roles/user_factory",
      {
        user_id: getCachedUserInfo().user_id,
      }
    ).catch(() => {
      return {};
    });
    let hasAccess = true;
    try {
      hasAccess = res.data.results.factory_ids.some(
        (item) => item == factoryId
      );
    } catch (error) {}
    setHasAccess(hasAccess);

    if (hasAccess) {
      initData(currentDate);
    }
    // console.log('hasAccess', hasAccess);
  };
  useEffect(() => {
    getAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dayColumns = useMemo(() => {
    return getDayColumns(setCurrentDate, setCurrentMenu, getChartData);
  }, [getChartData]);

  const [info, setInfo] = useState(null);

  const onResize = useCallback(() => {
    ["StackedLine", "percent", "percent1"].some((id) => {
      const chartDom = document.getElementById(id);
      if (chartDom) {
        const instance = echarts.getInstanceByDom(chartDom);
        instance?.resize?.();
      }
      return false;
    });
  }, []);

  return (
    <div className={style["wrapper"]}>
      <div
        style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <DomResize mountedCall={true} onResize={onResize} />
      </div>
      {!hasAccess ? (
        <div></div>
      ) : currentMenu === 1 ? (
        <div
          className="body-right"
          style={info ? { display: "flex", flexDirection: "column" } : {}}
        >
          <div className="body-title">
            <div>
              <span className="parent-menu">
                {factory}
                <span style={{ fontSize: 16, color: "#666" }}>
                  (电量：MWH，收益：万元， 电价：元/MWH)
                </span>
              </span>
            </div>

            <Space>
              {new Date(currentDate).getFullYear()}
              <span style={{ marginLeft: -5 }}>年</span>
              <span style={{ marginLeft: -5 }}>
                {new Date(currentDate).getMonth() + 1}
              </span>
              <span style={{ marginLeft: -5 }}>月</span>
              {info ? (
                <Button type="primary" onClick={() => setInfo(null)}>
                  月度统计表
                </Button>
              ) : (
                <Button type="primary" onClick={() => setInfo({})}>
                  月度趋势图
                </Button>
              )}
              <DatePicker
                value={currentDate}
                onChange={onChange}
                picker="month"
              />
            </Space>
          </div>
          <div style={info ? { display: "none" } : {}}>
            <div>
              <Table
                size="small"
                pagination={false}
                bordered
                dataSource={monthDataSource}
                columns={monthColumns}
              />
            </div>
            <div className="day-table">
              <Table
                size="small"
                pagination={false}
                bordered
                dataSource={dayDataSource}
                columns={dayColumns}
              />
            </div>
          </div>
          {info && (
            <div style={{ height: 0, flexGrow: 1 }}>
              <MonthlyChart {...{ monthDataSource, dayDataSource }} />
            </div>
          )}
        </div>
      ) : (
        <div className="body-right">
          <div>
            <div className="body-title">
              <div>
                <span className="parent-menu">用电监测-</span>
                <span>
                  {menuList.reduce((pre, cur) => {
                    if (cur.id === currentMenu) {
                      pre = cur.name;
                    }
                    return pre;
                  }, "")}
                </span>
              </div>
              <div className="back-button-wrapper">
                {hasBack ? (
                  <Button
                    className="back-button"
                    onClick={() => backToCurrentMenu(1, currentDate)}
                    type="primary"
                  >
                    返回
                  </Button>
                ) : null}
                <DatePicker
                  value={currentDate}
                  onChange={onChange}
                  picker="date"
                />
              </div>
            </div>
            <div>
              <div id="StackedLine"></div>
              <div id="percent"></div>
              <div id="percent1"></div>
              <div>
                <Table
                  size="small"
                  pagination={false}
                  bordered
                  dataSource={DailyChartDataSource}
                  columns={DailyChartColumns}
                  scroll={{
                    x: "max-content",
                  }}
                />
              </div>
              <div>
                <Table
                  size="small"
                  pagination={false}
                  bordered
                  dataSource={ProductionPlansDataSource}
                  columns={DailyChartColumns}
                  scroll={{
                    x: "max-content",
                  }}
                />
              </div>
              <div>
                <Table
                  className="no-tbody"
                  size="small"
                  pagination={false}
                  bordered
                  dataSource={[]}
                  columns={SuggestionColumns}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Page);
